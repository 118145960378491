<template>
  <div class="main">
    <div class="loginmain">
      <el-row>
        <el-col :span="24"><div class="miantittle">管理系统</div></el-col>
      </el-row>
      <el-row style="margin-bottom: 20px;margin-right: 20px">
        <el-col :span="6">
          <div class="inputfiled">账号：</div>
        </el-col>
        <el-col :span="18">
          <el-input
              placeholder="请输入账号"
              v-model="username">
            <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
          </el-input>
        </el-col>
      </el-row>
      <el-row style="margin-bottom: 40px;margin-right: 20px">
        <el-col :span="6">
          <div class="inputfiled">密码：</div>
        </el-col>
        <el-col :span="18">
          <el-input
              placeholder="请输入密码"
              v-model="password"
              show-password>
            <i slot="prefix" class="el-input__icon fa fa-key"></i>
          </el-input>
        </el-col>
      </el-row>
      <el-row style="text-align: center;">
        <el-button type="primary" @click="onlogin" style="width: 120px">登录</el-button>
        <el-button type="success" @click="onclear" style="width: 120px">重置</el-button>
      </el-row>

    </div>
  </div>
</template>

<script>
import {postUserLogin} from "../../network/user"
export default {
  name: "Login",
  data(){
    return{
      username:'admin',
      password:'123456'
    }
  },
  methods:{
    onlogin(){
      if (this.username==''){
        this.$message({
          message: '请输入账号！',
          type: 'warning'
        });
      }
      else if (this.password==''){
        this.$message({
          message: '请输入密码！',
          type: 'warning'
        });
      }
      else{
        let frmDate = new FormData();
        frmDate.append('username', this.username);
        frmDate.append('password', this.password);
        postUserLogin(frmDate).then(res => {
          if (res.islogin==false){
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }else
          {
            this.$store.commit('mGetToken', res.token)
            this.$router.push({path: '/main'})
            this.$message({
              message: '登录成功！',
              type: 'success'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    onclear() {
      this.username = '',
      this.password = ''
    },
  }
}
</script>

<style scoped>
.main{
  height: 100vh;
  width: 100%;
  background-color:rgb(255,255,255);
  background-image: url("../../assets/image/login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.loginmain{
  width: 500px;
  height: 280px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background-color: rgba(255,255,255,0.2);
  padding: 5px 5px;
}

.miantittle{
  text-align: center;
  margin: 15px 10px;
  font-size: 30px;
  font-family: 楷体;
  font-weight: bold;
  color: #1989fa;
}

.inputfiled {
  text-align: center;
  color: #222222;
  font-weight: bold;
  height:40px;
  line-height:40px;
  font-family: 楷体;
}
</style>